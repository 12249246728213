import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Chip, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useStyles } from "./styles"
import { TooltipProps } from 'recharts';
import { CustomTable, FormHeader } from 'components';
import { FormatTableFieldType, ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto';
import { useProfile } from 'hooks/use-profile';
import { ProfileType } from 'hooks/enums/ProfileType';
import { useForm } from 'react-hook-form';
import { IOrganizationDTO } from 'data/dtos/operation/i-organization-dto';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import { ProposalPerformanceStatus, ProposalStatus } from 'hooks/enums/ProposalStatus';
import NoProposals from 'components/no-proposals';
import FunnelChart, { FunnelChartCategories, FunnelChartProps } from './FunnelChart';
import PieChart, { PieChartCategories } from './PieChart';

export interface ProposalReport {
  proposalStatuses:ProposalStatusesReport[],
  proposals: ProposalReportProposal[]
}

export interface ProposalStatusesReport {
  amount: string,
  totalFinancingValue: string,
  status: ProposalPerformanceStatus
}

export interface ProposalReportProposal {
  id: string
  proposalCreatedAt: Date
  proposalPersonCompleteName: string
  proposalFinancingValue: string
  proposalStatus: ProposalStatus
  proposalPerformanceStatus: ProposalPerformanceStatus
  proposalDeclineReason: string
  assessorID: string
  assessorName: string
  organizationID: string
  organizationName: string
  partnerBankID: string
  partnerBankName: string
}

const headCells: ITableHeadCellDTO[] = [
  {
    id: 'proposalPersonCompleteName',
    label: 'Cliente',
    width: 2
  },
  {
    id: 'proposalCreatedAt',
    label: 'Data da Proposta',
    width: 2,
    format: FormatTableFieldType.DATE
  },
  {
    id: 'partnerBankName',
    label: 'Banco Parceiro',
    width: 2
  },
  {
    id: 'proposalFinancingValue',
    label: 'Valor Proposta',
    width: 2,
    format: FormatTableFieldType.MONEY
  },
  {
    id: 'proposalPerformanceStatus',
    label: 'Fase',
    width: 2,
    format: FormatTableFieldType.PROPOSAL_PERFORMANCE_STATUS
  },
  {
    id: 'proposalDeclineReason',
    label: 'Motivo',
    width: 2
  },
]

const officerGestorHeadCells: ITableHeadCellDTO[] = [
  {
    id: 'proposalPersonCompleteName',
    label: 'Cliente',
    width: 2
  },
  {
    id: 'proposalCreatedAt',
    label: 'Data da Proposta',
    width: 1,
    format: FormatTableFieldType.DATE
  },
  {
    id: 'partnerBankName',
    label: 'Banco Parceiro',
    width: 1
  },
  {
    id: 'proposalFinancingValue',
    label: 'Valor Proposta',
    width: 1,
    format: FormatTableFieldType.MONEY
  },
  {
    id: 'proposalPerformanceStatus',
    label: 'Fase',
    width: 1,
    format: FormatTableFieldType.PROPOSAL_PERFORMANCE_STATUS
  },
  {
    id: 'proposalDeclineReason',
    label: 'Motivo',
    width: 1
  },
  {
    id: 'assessorName',
    label: 'Assesor',
    width: 1
  },
  {
    id: 'organizationName',
    label: 'Parceiro',
    width: 3
  },
]

const Performance: React.FC = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [orderByDirection, setOrderByDirection] = useState(true)
  const [rowsCount, setRowsCount] = useState(0)
  const [columnOrder, setColumnOrder] = useState<('ASC' | 'DESC')[]>([])
  const { selectedProfile } = useProfile();
  const [proposalReportList, setProposalReportList] = useState<ProposalReport>({proposals: [], proposalStatuses: []})
  const [organizationsList, setOrganizationsList] = useState<IOrganizationDTO[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<string>('ALL')
  const [pieChartCategories, setPieCategories] = useState<PieChartCategories[]>([])
  const [funnelChartCategories, setFunnelChartCategories] = useState<FunnelChartCategories[]>([])
  const [refusedProposalsAmount, setRefusedProposalsAmount] = useState<Number>()
  // const shouldShowOrganizationFilter = selectedProfile === ProfileType.ADMIN || selectedProfile === ProfileType.OFFICER
  // const shouldShowAssessorFilter = selectedProfile === ProfileType.ADMIN || selectedProfile === ProfileType.OFFICER || selectedProfile === ProfileType.GESTOR
  const tableHeadCells = selectedProfile === ProfileType.ADMIN || selectedProfile === ProfileType.OFFICER || selectedProfile === ProfileType.GESTOR ? officerGestorHeadCells : headCells
  const shouldDisplayPieChart = pieChartCategories.some(category => category.value !== 0)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number, ) => {
    setPage(newPage);
  }
  const options = {
    chart: {
      type: 'funnel'
    },
    plotOptions: {
      funnel: {
        neckWidth: '30%',
        neckHeight: '30%'
      }
    },
    series: [{
      name: 'Website visits',
      data: [500, 400, 300, 200, 100]
    }],
    labels: ['Awareness', 'Interest', 'Consideration', 'Intent', 'Purchase']
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const history = useHistory()


  const prepareChartData = (proposalStatuses: ProposalStatusesReport[]) => {
    const pieChart: PieChartCategories[] = []
    const temp: FunnelChartCategories[] = [];
    let refusedProposalsAmount: number = 0

    for(const proposalStatus of proposalStatuses) {
      switch(proposalStatus.status) {
        case ProposalPerformanceStatus.MAPPED:
          temp.push({
            name: 'Mapeada',
            value: Number(proposalStatus.amount),
          })
          break
        case ProposalPerformanceStatus.IN_ANALYSIS:
          temp.push({
            name: 'Análise de Documentos',
            value: Number(proposalStatus.amount),
          })
          break
        case ProposalPerformanceStatus.HIRING:
          temp.push({
            name: 'Em Contratação',
            value: Number(proposalStatus.amount),
          })
          break
        case ProposalPerformanceStatus.COMPLETED:
          temp.push({
            name: 'Concluído',
            value: Number(proposalStatus.amount),
          })
          break

        case ProposalPerformanceStatus.DECLINED_CREDIT_REFUSAL:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Recusada de crédito',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
        case ProposalPerformanceStatus.DECLINED_LACK_OF_INFORMATION:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Recusada (falta de informação)',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
        case ProposalPerformanceStatus.DECLINED_COMPETITOR_CONDITIONS:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Condições dos concorrente',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
        case ProposalPerformanceStatus.DECLINED_ABANDONMENT:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Abandono',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
        case ProposalPerformanceStatus.DECLINED_BY_CLIENT:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Cancelado pelo cliente',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
      }
    }

    setRefusedProposalsAmount(refusedProposalsAmount)
    setPieCategories(pieChart)
    setFunnelChartCategories(temp)
  }

  const loadOrganizations = async () => {
    setLoading(1)

    await api
      .post('/organizations/list', {search, page, rowsPerPage, columnOrder, onlyActive: false})
      .then(async listResponse => {
        const { data } = listResponse.data
        setOrganizationsList(data)

        await api
          .post('/organizations/count', {search})
          .then(countResponse => {
            const { count } = countResponse.data.data
            setRowsCount(count)
          })
          .then(() => setLoading(0))
          .catch(error => {
            return error
          })
      })
      .catch(error => {
        return error
      })
  }

  const loadProposals = async () => {
    setLoading(1)

    let queryParams = `?search=${search}`

    if(selectedOrganization && selectedOrganization !== 'ALL') {
      queryParams += `&organizationID=${selectedOrganization}`
    }

    await api
      .get('/proposals' + queryParams)
      .then(async listResponse => {
        const data = listResponse.data as ProposalReport

        setProposalReportList(data)
        prepareChartData(data.proposalStatuses)
      })
      .catch(error => {
        return error
      })
      .finally(() => {
        setLoading(0)
      })
  }

  useEffect(() => {
    loadProposals()
  }, [search, selectedOrganization])

  // useEffect(() => {
  //   if(shouldShowOrganizationFilter) {
  //     loadOrganizations()
  //   }
  // }, [shouldShowOrganizationFilter])

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<IOrganizationDTO>({
    defaultValues: {
      cnpj: '',
      legalName: '',
      tradeName: '',
      address: '',
      number: '',
      neighborhood: '',
      complement: '',
      zipCode: '',
      stateID: null,
      cityID: null,
      email: '',
      phoneNumber: '',
      accountAgency: '',
      accountNumber: '',
      financialInstitutionID: null,
      accountBankID: null,
      disabled: false,
    }
  })

  if(!proposalReportList || !proposalReportList.proposals) {
    return <NoProposals />
  }

  return (
    <Paper elevation={0} className={classes.paper}>

      <Typography variant='h5' className={classes.formTitle}>
        Performance
      </Typography>

      <Grid container spacing={1} className={classes.formContainer} >
        {/* {shouldShowOrganizationFilter &&
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="caption" display="block" gutterBottom>
                Parceiro
              </Typography>
              <TextField
                id="organizationID"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={selectedOrganization}
                select
              >
                <MenuItem
                  key={'ALL'}
                  value={'ALL'}
                  onClick={() => setSelectedOrganization('ALL')}
                >
                  Todos parceiros
                </MenuItem>
              {organizationsList.map(organization => (
                <MenuItem
                  key={organization.id}
                  value={organization.id}
                  onClick={() => setSelectedOrganization(organization.id)}
                >
                  {organization.legalName}
                </MenuItem>
              ))}
              </TextField>
            </Grid>
          </Grid>
        } */}

        {/* {shouldShowAssessorFilter &&
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="caption" display="block" gutterBottom>
                Assessor
              </Typography>
              <TextField
                id="stateId"
                error={!!errors.stateID}
                helperText={errors?.stateID?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={`${watch().stateID}`}
                select
                {...register("stateID", { onChange: (e) => {
                  setValue("stateID", e.target.value)
                  // setSelectedStateID(e.target.value)
                  // handleChange(e)
                }})}
              > */}
              {/* {statesB.map((state) => (
                <MenuItem
                  key={state.id}
                  value={state.id}
                >
                  {state.code}
                </MenuItem>
              ))} */}
                {/* <MenuItem
                  key={'a'}
                  value={'asd'}
                >
                  João Fagundes da Silva
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        } */}
      </Grid>

      <Box display="flex" alignItems="center" justifyContent="space-evenly" flexDirection="row" paddingBottom={3}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h6" gutterBottom>
            Situação das Oportunidades
          </Typography>
          <FunnelChart categories={funnelChartCategories} />
        </Box>
          { shouldDisplayPieChart &&
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="h6" gutterBottom mb={3}>
                Motivo das Perdas {` - ${refusedProposalsAmount} perda(s) ` }
              </Typography>
              <PieChart  categories={pieChartCategories} />
            </Box>
          }
      </Box>

      <CustomTable
        headCells={tableHeadCells}
        rows={proposalReportList.proposals}
        totalRows={rowsCount}
        handleSearch={setSearch}
        isLoading={loading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        orderByDirection={orderByDirection}
        setOrderByDirection={setOrderByDirection}
        buttons={[
          {
            name: 'Detalhes',
            icon: FileOpenIcon,
            onClick(proposalID) {
              history.push(`proposals/edit/${proposalID}`)
            },
          }
        ]}
      />

    </Paper>
  )
}

export default Performance
