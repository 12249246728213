import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './route'
import SignIn from 'pages/authentication/sign-in'
import SignUp from 'pages/authentication/sign-up'
import Home from 'pages/authentication/home'
import ForgotPassword from 'pages/authentication/forgot-password'
import ResetPassword from 'pages/authentication/reset-password'
import UserForm from 'pages/security/user/form'
import OrganizationList from 'pages/operation/organization/list'
import OrganizationForm from 'pages/operation/organization/form'
import InviteForm from 'pages/operation/invite/form'
import SimulationList from 'pages/operation/simulation/list'
import SimulationForm from 'pages/operation/simulation/form'
import NotificationList from 'pages/operation/notification/list'
import NotificationForm from 'pages/operation/notification/form'
import NotificationDestinationList from 'pages/operation/notification-destination/list'
import NotificationDestinationForm from 'pages/operation/notification-destination/form'
import NotFound from 'pages/common/not-found'
import ManagerForm from 'pages/security/manager/form'
import ProposalForm from 'pages/security/proposal/form'
import Commissions from 'pages/operation/commissions'
import Performance from 'pages/operation/performance'
import FollowUp from 'pages/security/proposal/follow-up'
import PublicProposalForm from 'pages/security/proposal/public/proposal'

const Routes: React.FC = () => (
  <>
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route exact path="/home" component={Home} isPrivate />

      <Route exact path="/public/proposals/new" component={PublicProposalForm} />
      <Route exact path="/proposals/new" component={ProposalForm} isPrivate />
      <Route exact path="/proposals/edit/:proposalID" component={ProposalForm} isPrivate />

      <Route exact path="/commissions" component={Commissions} isPrivate />
      <Route exact path="/performance" component={Performance} isPrivate />


      <Route exact path="/users/new" component={UserForm} isPrivate />
      <Route path="/users/edit/:id" component={UserForm} isPrivate />
      <Route exact path="/user/new" component={ManagerForm} isPrivate />

      <Route exact path="/signup" component={SignUp} />
      <Route path="/signup/:id" component={SignUp} />

      <Route exact path="/invites" component={InviteForm} isPrivate />

      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password/:token" component={ResetPassword} />

      <Route exact path="/follow-up" component={FollowUp} isPrivate />

      <Route exact path="/organizations" component={OrganizationList} isPrivate />
      <Route exact path="/organizations/new" component={OrganizationForm} isPrivate />
      <Route path="/organizations/edit/:organizationID" component={OrganizationForm} isPrivate />


      <Route exact path="/simulations" component={SimulationList} isPrivate />
      <Route exact path="/simulations/new" component={SimulationForm} isPrivate />
      <Route path="/simulations/edit/:id" component={SimulationForm} isPrivate />

      <Route exact path="/notifications" component={NotificationList} isPrivate />
      <Route exact path="/notifications/new" component={NotificationForm} isPrivate />
      <Route path="/notifications/edit/:id" component={NotificationForm} isPrivate />

      <Route exact path="/notification-destinations" component={NotificationDestinationList} isPrivate />
      <Route exact path="/notification-destinations/new" component={NotificationDestinationForm} isPrivate />
      <Route path="/notification-destinations/edit/:id" component={NotificationDestinationForm} isPrivate />


      <Route path="*" component={NotFound} isPrivate />
    </Switch>
  </>

)

export default Routes
