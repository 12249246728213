interface ITableHeadCellDTO {
  id: string
  label: string
  numeric?: boolean
  width?: number
  format?: FormatTableFieldType
  cancelIconEnabled?: boolean
  resendIconEnabled?: boolean
  allowSort?: boolean
}

export enum FormatTableFieldType {
  CNPJ = 'CNPJ',
  DATE = 'DATE',
  INVITE_STATUS = 'INVITE_STATUS',
  MONEY = 'MONEY',
  PROPOSAL_PERFORMANCE_STATUS = 'PROPOSAL_PERFORMANCE_STATUS',
  NO_FORMAT = 'NO_FORMAT',
  BOOLEAN = 'BOOLEAN',
}

export type { ITableHeadCellDTO }
